@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

$charcoalGrey: #3a3f42;
$coolGrey: #b6b9bb;
$turquoise: #00c4b5;
$battleshipGrey: #707274;
$rosyPink: #ef5d60;
$paleGrey: #eef0f2;
$lightGrey: #e7eceb;

a {
  color: inherit;
  text-decoration: none;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ac_link{
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.ac_button{
  border-radius:3px;
  color: #fff;
  background: #00c4b5;
  padding: 8px 24px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border: none;
  &:hover {
    background: #00a99c;
    cursor: pointer;
  }
  &:disabled, &.disabled, &[disabled='disabled']{
    background: #acc4bf;
    opacity:0.2;
    cursor: default;
  }
}

.dropdown {
     position: relative;
     display: inline-block;
}
.drop_ul{
     list-style-type: none;
     margin: 0;
     padding: 0;
     top: 100%;
     right:0px;
     width: 100%;
     background-color: #fff;
     position: absolute;
     box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
     z-index: 1;
}
.drop_li,.drop_a {
     color: #000;
     text-decoration: none;
}
 .drop_li {
     padding: 8px 16px;
     border-bottom: 1px solid #e5e5e5;
     text-align: center;
}
 .drop_li:last-child {
     border-bottom: none;
}
 .drop_li:hover {
     background-color: #e5e5e5;
}
.drop_button{
  border-radius:3px;
  color: #fff;
  background: #00c4b5;
  padding: 8px 24px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 0;
  font-size: 14px;
  border: none;
  &:hover {
    background: #00a99c;
    cursor: pointer;
  }
  &:disabled, &.disabled, &[disabled='disabled']{
    background: #acc4bf;
    opacity:0.2;
    cursor: default;
  }
}
.drop_button_caret{
    position: absolute;
    right: 30px;
    margin: 8px 0px 0px 20px;
    border: 8px solid;
    border-color: white transparent transparent transparent;
}

.text_title{
  font-weight: 700;
  font-size: 18px;
  color: $charcoalGrey;
}

.text_title_small{
  font-weight: 700;
  font-size: 16px;
  color: $charcoalGrey;
}

.text_heading_light{
  font-weight: 700;
  font-size: 14px;
  color: $coolGrey;
}

.text_heading{
  font-weight: 700;
  font-size: 14px;
  color: $charcoalGrey;
}

.text_body{
  font-weight: 600;
  font-size: 14px;
  color: $charcoalGrey;
  a{
    @extend .text_body_link;
  }
}
.text_body_link{
  font-weight: 500;
  font-size: 14px;
  color: $turquoise;
}

.text_body_big{
  font-weight: 500;
  font-size: 16px;
  color: $charcoalGrey;
  a{
    @extend .text_body_big_link;
  }
}

.text_body_big_link{
  font-weight: 500;
  font-size: 16px;
  color: $turquoise;
}

.text_body_placeholder{
  font-weight: 600;
  font-size: 14px;
  color: $coolGrey;
}
.text_body_light{
  font-weight: 600;
  font-size: 14px;
  color: $battleshipGrey;
}
.text_caption{
  font-weight: 600;
  font-size: 12px;
  color: $battleshipGrey;
}
.text_caption_dark{
  font-weight: 700;
  font-size: 12px;
  color: $charcoalGrey;
}
.text_caption_light{
  font-weight: 600;
  font-size: 12px;
  color: $coolGrey;
}
.text_caption_small{
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  color: $coolGrey;
}

.text_link{
  font-weight: 700;
  font-size: 12px;
  color: $turquoise;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.text_link_big{
  @extend .text_link;
  font-weight: 600;
  font-size: 14px;
}

.text_error{
  font-weight: 600;
  font-size: 12px;
  color: $rosyPink;
}

.text_success{
  font-weight: 600;
  font-size: 12px;
  color: #00bfa9;
}

.header-content{
  max-width: 1208px;
  margin: auto;
  height: 80px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 720px) {
    padding: 0 16px;
  }
}

.network-title{
  font-weight: 700;
  font-size: 24px;
  @media screen and (max-width: 720px) {
    font-size: 14px;
  }
}

.title-caption{
  font-size: 16px;
  line-height: 1.5em;
  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
}

.header-logo{
  max-height: 64px;
  margin-right: 24px;
  @media screen and (max-width: 720px) {
    margin-right: 8px;
  }
}

.banner-text-container{
  min-height: 240px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
  background-color: #3a3f42;
  @media screen and (max-width: 720px) {
    font-size: 16px;
    min-height: 200px;
  }
}

.quill-content {
  color: #666;
  font-size: 13px;
}

.quill-content * {
  word-break: break-word;
}

.quill-content ul, .quill-content ol {
  list-style-position: outside;
  padding-left: 1.5em;
}
.quill-content ul {
 list-style-type: disc;
}
.quill-content ol {
   list-style-type: decimal;
}
.quill-content ul ul, .quill-content ol ul {
   list-style-type: circle;
}
.quill-content ol ol, .quill-content ul ol {
   list-style-type: lower-latin;
}

.quill-content img {
  max-width: 100%;
}

.quill-content > * {
  cursor: text;
}
.quill-content p,
.quill-content ol,
.quill-content ul,
.quill-content pre,
.quill-content blockquote,
.quill-content h1,
.quill-content h2,
.quill-content h3,
.quill-content h4,
.quill-content h5,
.quill-content h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-content ol,
.quill-content ul {
  padding-left: 1.5em;
}
.quill-content ol > li,
.quill-content ul > li {
  list-style-type: none;
}
.quill-content ul > li::before {
  content: '\2022';
}
.quill-content ul[data-checked=true],
.quill-content ul[data-checked=false] {
  pointer-events: none;
}
.quill-content ul[data-checked=true] > li *,
.quill-content ul[data-checked=false] > li * {
  pointer-events: all;
}
.quill-content ul[data-checked=true] > li::before,
.quill-content ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.quill-content ul[data-checked=true] > li::before {
  content: '\2611';
}
.quill-content ul[data-checked=false] > li::before {
  content: '\2610';
}
.quill-content li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.quill-content li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.quill-content li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.quill-content ol li:not(.ql-direction-rtl),
.quill-content ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.quill-content ol li.ql-direction-rtl,
.quill-content ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.quill-content ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.quill-content ol li:before {
  content: counter(list-0, decimal) '. ';
}
.quill-content ol li.ql-indent-1 {
  counter-increment: list-1;
}
.quill-content ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.quill-content ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-2 {
  counter-increment: list-2;
}
.quill-content ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.quill-content ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-3 {
  counter-increment: list-3;
}
.quill-content ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.quill-content ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-4 {
  counter-increment: list-4;
}
.quill-content ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.quill-content ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-5 {
  counter-increment: list-5;
}
.quill-content ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.quill-content ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-6 {
  counter-increment: list-6;
}
.quill-content ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.quill-content ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.quill-content ol li.ql-indent-7 {
  counter-increment: list-7;
}
.quill-content ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.quill-content ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.quill-content ol li.ql-indent-8 {
  counter-increment: list-8;
}
.quill-content ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.quill-content ol li.ql-indent-8 {
  counter-reset: list-9;
}
.quill-content ol li.ql-indent-9 {
  counter-increment: list-9;
}
.quill-content ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.quill-content .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.quill-content li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.quill-content .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.quill-content li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.quill-content .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.quill-content li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.quill-content .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.quill-content li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.quill-content .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.quill-content li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.quill-content .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.quill-content li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.quill-content .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.quill-content li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.quill-content .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.quill-content li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.quill-content .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.quill-content li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.quill-content .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.quill-content li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.quill-content .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.quill-content li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.quill-content .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.quill-content li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.quill-content .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.quill-content li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.quill-content .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.quill-content li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.quill-content .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.quill-content li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.quill-content .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.quill-content li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.quill-content .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.quill-content li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.quill-content .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.quill-content li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.quill-content .ql-video {
  display: block;
  max-width: 100%;
}
.quill-content .ql-video.ql-align-center {
  margin: 0 auto;
}
.quill-content .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.quill-content .ql-bg-black {
  background-color: #000;
}
.quill-content .ql-bg-red {
  background-color: #e60000;
}
.quill-content .ql-bg-orange {
  background-color: #f90;
}
.quill-content .ql-bg-yellow {
  background-color: #ff0;
}
.quill-content .ql-bg-green {
  background-color: #008a00;
}
.quill-content .ql-bg-blue {
  background-color: #06c;
}
.quill-content .ql-bg-purple {
  background-color: #93f;
}
.quill-content .ql-color-white {
  color: #fff;
}
.quill-content .ql-color-red {
  color: #e60000;
}
.quill-content .ql-color-orange {
  color: #f90;
}
.quill-content .ql-color-yellow {
  color: #ff0;
}
.quill-content .ql-color-green {
  color: #008a00;
}
.quill-content .ql-color-blue {
  color: #06c;
}
.quill-content .ql-color-purple {
  color: #93f;
}
.quill-content .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.quill-content .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.quill-content .ql-size-small {
  font-size: 0.75em;
}
.quill-content .ql-size-large {
  font-size: 1.5em;
}
.quill-content .ql-size-huge {
  font-size: 2.5em;
}
.quill-content .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.quill-content .ql-align-center {
  text-align: center;
}
.quill-content .ql-align-justify {
  text-align: justify;
}
.quill-content .ql-align-right {
  text-align: right;
}
.quill-content.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.search_bar_user{
  max-width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  color: rgb(187, 187, 187);
  padding-left: 20px;
}
.search_bar_user input{
  width: calc(100% - 40px);
  padding: 20px 20px 20px 10px;
  border: 0px;
  border-radius: inherit;
  height: 100%
}
.search_bar_user input::placeholder{
  color: rgb(187, 187, 187);
}
.search_bar_user input:focus{
  outline: none;
}
.search_results{
  max-height: 300px;
  width: 100%;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  overflow: auto
}

.search_results .list_item{
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.paragraph_text p{
  margin: 0px;
  padding: 0px;
}

.job_posting_tag{
  float: left;
  border: 1px solid #bbb;
  padding: 4px 8px;
  margin: 2px;
  border-radius: 16px;
  background: #fff;
}

.ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spaced-text {
  p {
    margin: 1em 0;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.ac_button_small{
  border-radius:3px;
  color: #fff;
  background: #00c4b5;
  padding: 8px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  border: none;
  &:hover{
    background: #00a99c;
    cursor: pointer;
  }
  &:disabled, &.disabled{
    background: #acc4bf;
    opacity:0.2;
    cursor: default;
  }
}
